import React, { useState } from "react";
import "../../scss/common/imageGallery.scss";
import DHC1 from "../../assets/images/gallery/DigitalHealthConference.jpg";
import DHC2 from "../../assets/images/gallery/DigitalHealthConference2.jpg";
import DHC3 from "../../assets/images/gallery/DigitalHealthConference3.jpg";
import DHC4 from "../../assets/images/gallery/DigitalHealthConference4.jpg";
import DHC5 from "../../assets/images/gallery/DigitalHealthConference5.jpg";
import GSurvey from "../../assets/images/gallery/Groundsurvey.jpg";
import GSurvey2 from "../../assets/images/gallery/Groundsurvey2.jpg";
import GSurvey3 from "../../assets/images/gallery/Groundsurvey3.jpg";
import Hackathon from "../../assets/images/gallery/Hackathon.jpg";
import Hackathon2 from "../../assets/images/gallery/Hackathon2.jpg";
import Nabbingo1 from "../../assets/images/gallery/Nabbingo1.jpg";
import Nabbingo2 from "../../assets/images/gallery/Nabbingo2.jpg";
import Nabbingo3 from "../../assets/images/gallery/Nabbingo3.jpg";
import Nabbingo4 from "../../assets/images/gallery/Nabbingo4.jpg";
import Nabbingo5 from "../../assets/images/gallery/Nabbingo5.jpg";
import Nabbingo6 from "../../assets/images/gallery/Nabbingo6.jpg";
import Nabbingo7 from "../../assets/images/gallery/Nabbingo7.jpg";
import MeetingHospital from "../../assets/images/gallery/Meetinghospitals.jpg";
import SierraVisit from "../../assets/images/gallery/SierraLeonemeeting.jpg";
import ImagePopup from "./ImagePopup";

const GalleryData = [
  { imageUrl: DHC1, tag: "DHC", title: "" },
  { imageUrl: DHC2, tag: "DHC", title: "" },
  { imageUrl: DHC3, tag: "DHC", title: "" },
  { imageUrl: DHC4, tag: "DHC", title: "" },
  { imageUrl: DHC5, tag: "DHC", title: "" },
  { imageUrl: Hackathon, tag: "Hackathon", title: "" },
  { imageUrl: Hackathon2, tag: "Hackathon", title: "" },
  { imageUrl: GSurvey, tag: "Survey", title: "" },
  { imageUrl: GSurvey2, tag: "Survey", title: "" },
  { imageUrl: GSurvey3, tag: "Survey", title: "" },
  { imageUrl: MeetingHospital, tag: "Hospital", title: "" },
  { imageUrl: SierraVisit, tag: "", title: "visit" },
  { imageUrl: Nabbingo1, tag: "Nabbingo", title: "" },
  { imageUrl: Nabbingo2, tag: "Nabbingo", title: "" },
  { imageUrl: Nabbingo3, tag: "Nabbingo", title: "" },
  { imageUrl: Nabbingo4, tag: "Nabbingo", title: "" },
  { imageUrl: Nabbingo5, tag: "Nabbingo", title: "" },
  { imageUrl: Nabbingo6, tag: "Nabbingo", title: "" },
  { imageUrl: Nabbingo7, tag: "Nabbingo", title: "" },
];

const ImageGallery = () => {
  const [selectedImage, setSelectedImage] = useState({
    status: false,
    img: "",
  });
  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const visibleImages = showAll ? GalleryData : GalleryData.slice(0, 5);

  return (
    <div className="container">
      <div className="section-padding">
        <h2 className="text-color_444343 text-center title-underline">
          Medref Gallery
        </h2>
        <div className="gallery lg:mt-20 mt-10">
          {visibleImages.map((data, index) => {
            return (
              <div
                className="item"
                key={index}
                onClick={() =>
                  setSelectedImage({ status: true, img: data?.imageUrl })
                }
              >
                <img src={data?.imageUrl} alt={data?.tag} />
              </div>
            );
          })}
        </div>
        <button className="show-more-btn" onClick={handleShowMore}>
          {showAll ? "Show Less" : "Show More"}
        </button>
      </div>
      {selectedImage?.status && (
        <ImagePopup
          closeHandler={() => setSelectedImage({ status: false, img: "" })}
          image={selectedImage?.img}
        />
      )}
    </div>
  );
};

export default ImageGallery;
