import React from "react";
import MissionImg from "../../../assets/images/our-mission.svg";
import VisionImg from "../../../assets/images/our-vision.svg";

const data = [
  {
    title: "Our Mission",
    imgUrl: MissionImg,
    description:
      "To bridge the gap in healthcare access and ensure every individual, regardless of location or background, has the opportunity to receive quality medical care.",
    bgColor: "#A8D0E1",
  },
  {
    title: "Our Vision",
    imgUrl: VisionImg,
    description:
      "A world where healthcare is a universal right, not a privilege. We envision a future where everyone can access the resources and services they need to live healthy and fulfilling lives.",
    bgColor: "#B4E5D6",
  },
];

const MissionVisionSection = () => {
  return (
    <div className="pt-11">
      <div className="flex flex-col items-center">
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className="flex even:lg:flex-row-reverse max-lg:flex-col"
              style={{ backgroundColor: item?.bgColor }}
            >
              <div className="basis-1/2 shadow-sectionShadow bg-white lg:p-10 p-5 relative z-[2]">
                <img
                  src={item?.imgUrl}
                  alt="mission-vision"
                  className="w-full h-full max-w-[50%] m-auto max-lg:h-[150px]"
                />
              </div>
              <div className="flex flex-col gap-7 basis-1/2 lg:p-20 p-10 relative z-[1]">
                <h2 className="text-color_444343 font-bold">{item?.title}</h2>
                <div
                  className="text-color_7D7987"
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MissionVisionSection;
