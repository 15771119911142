import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  const {
    name,
    type,
    classname,
    width,
    attribute,
    clickHandler,
    btnType,
    children,
    isLoading,
    inverted,
  } = props;
  if (btnType === "btn") {
    return (
      <button
        type={type}
        name={name}
        className={`relative button flex lg:gap-4 gap-2 justify-center items-center lg:h-[40px] h-[40px] whitespace-nowrap text-white font-medium lg:py-4 lg:px-5 py-2 px-4 w-full bg-primary border border-primary shadow-buttonShadow rounded-md capitalize transition-all duration-300 hover:bg-white hover:text-primary ${classname} ${
          inverted && "inverted"
        }`}
        style={{ maxWidth: width }}
        onClick={clickHandler}
        {...attribute}
      >
        {isLoading && <span className="btn-loader"></span>}
        <span
          className={`${isLoading && "opacity-0"} flex gap-1.5 items-center`}
        >
          {children}
        </span>
      </button>
    );
  } else if (btnType === "link") {
    return (
      <Link
        className={`relative button flex lg:gap-4 gap-2 justify-center items-center lg:h-[40px] h-[40px] whitespace-nowrap text-white font-medium lg:py-4 lg:px-5 py-2 px-4 w-full bg-primary border border-primary shadow-buttonShadow rounded-md capitalize transition-all duration-300 hover:bg-white hover:text-primary ${classname} ${
          inverted && "inverted"
        }`}
        style={{ maxWidth: width }}
        to={clickHandler}
        {...attribute}
      >
        {isLoading && <span className="btn-loader"></span>}
        <span
          className={`${isLoading && "opacity-0"} flex gap-1.5 items-center`}
        >
          {children}
        </span>
      </Link>
    );
  } else {
    return null;
  }
};

export default Button;
