import React from "react";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MainLogoWhite,
  TwitterIcon,
} from "../../assets/Icons";

const Footer = () => {
  return (
    <div className="bg-primary relative overflow-hidden">
      <div className="header-container relative z-[2]">
        <div className="lg:py-20 py-10 flex">
          <div className="lg:w-[40%]">
            <Link to="/">
              <MainLogoWhite />
            </Link>
            <p className="text-white py-4">
              MedRef provides progressive, and affordable healthcare, accessible
              on mobile and online for everyone
            </p>
            <p className="text-white">
              ©MedRef PTY LTD 2023. All rights reserved
            </p>
            <div className="mt-5 flex gap-3 relative z-[2]">
              <Link
                to="https://www.facebook.com/Healthbridgesolutions?mibextid=ZbWKwL"
                target="_blank"
                className="hover w-[30px] h-[30px] border border-white rounded-md flex items-center justify-center transition-all duration-300 hover:scale-105 p-1.5"
              >
                <FacebookIcon />
              </Link>
              <Link
                to="https://www.linkedin.com/company/medrefhealthbridge-solutions/"
                target="_blank"
                className="hover w-[30px] h-[30px] border border-white rounded-md flex items-center justify-center transition-all duration-300 hover:scale-105 p-1.5"
              >
                <LinkedinIcon />
              </Link>
              <Link
                to="https://www.instagram.com/medrefhealthbridge?igsh=c2ozd3d5eWZ5YXdh"
                target="_blank"
                className="hover w-[30px] h-[30px] border border-white rounded-md flex items-center justify-center transition-all duration-300 hover:scale-105 p-1.5"
              >
                <InstagramIcon />
              </Link>
              <Link
                to="https://x.com/medhealthbridge?t=55rI0ZNJI6eTXl1k5-afpQ&s=09"
                target="_blank"
                className="hover w-[30px] h-[30px] border border-white rounded-md flex items-center justify-center transition-all duration-300 hover:scale-105 p-1.5"
              >
                <TwitterIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
