import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../../pages/About";
import ContactUs from "../../pages/ContactUs";
import Home from "../../pages/Home";
import Footer from "./Footer";
import Header from "./Header";
import BlogPage from "../../pages/BlogPage";
import Services from "../../pages/Services";

const Layout = () => {
  return (
    <div>
      <Header />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="blogs" element={<BlogPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
