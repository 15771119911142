import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const MenusList = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "About us",
    link: "/about-us",
  },
  {
    name: "Blog",
    link: "/blogs",
  },
  {
    name: "Contact us",
    link: "/contact",
  },
];

const Menu = ({ setIsMenuOpen }) => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="flex gap-7 max-lg:hidden">
        {MenusList.map((menu, index) => {
          return (
            <div key={index}>
              <Link
                // onClick={() => {
                //   scrollToSection(menu?.link);
                // }}
                to={menu?.link}
                className={`menu-link text-color_444343 transition-all duration-300 hover:text-primary text-base font-medium cursor-pointer ${
                  pathname === menu?.link && "active"
                }`}
              >
                {menu?.name}
              </Link>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-2 lg:hidden px-4 py-7">
        {MenusList.map((menu, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setIsMenuOpen(false);
              }}
            >
              <Link
                to={menu?.link}
                className={`menu-link text-color_444343 hover:text-primary text-base font-medium cursor-pointer py-2 block ${
                  pathname === menu?.link && "active"
                }`}
              >
                {menu?.name}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Menu;
