import React from "react";
import BlogPost from "../components/page/blog-page/BlogPost";

const BlogsData = [
  {
    title: "MedRef’s Journey to Empower Mothers and Save Lives",
    image: "https://miro.medium.com/v2/1*0YQCY1ktGf7zj25zS_Fi7Q.jpeg",
    link: "https://medium.com/@josh_asiimwe/medrefs-journey-to-empower-mothers-and-save-lives-4ddbf82a382d",
  },
  {
    title:
      "Healthy Moms, Happy Babies: Discover the features of Medref’s Services",
    image: "",
    link: "https://medium.com/@crystaltusuu1234/healthy-moms-happy-babies-discover-the-features-of-medrefs-services-adf3f53c79c0",
  },
];
const BlogPage = () => {
  return (
    <div className="container">
      <div className="section-padding">
        <h2 className="text-color_444343 text-center title-underline">
          Our Blog
        </h2>
        <div className="mt-[50px] flex lg:gap-10 gap-5 flex-wrap">
          {BlogsData.map((blog, index) => {
            return <BlogPost data={blog} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
