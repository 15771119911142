import React from "react";
import { Link } from "react-router-dom";
import {
  InternetIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
} from "../assets/Icons";
import ContactUsImg from "../assets/images/contactUs-section.svg";
import "../scss/page/Contact.scss";

const ContactUs = () => {
  return (
    <div className="contact-us-page section-padding">
      <div className="container">
        <div className="flex rounded-2xl bg-white w-full lg:max-w-[80%] m-auto max-lg:flex-col-reverse">
          <div className="bg-primary lg:basis-[40%] md:p-10 p-5 lg:rounded-l-2xl max-lg:rounded-b-2xl">
            <h2 className="text-white">Let's Connect With Us!</h2>
            <div className="mt-10 flex flex-col gap-3">
              <div className="text-white text-base font-medium flex gap-1 items-center">
                <span className="w-[30px] h-[30px] bg-white rounded-full flex items-center justify-center">
                  <PhoneIcon />
                </span>
                <Link
                  to="tel:(+917434833281)"
                  className="max-w-[calc(100%-40px)] break-words pl-2"
                >
                  +917434833281
                </Link>
                /
                <Link
                  to="tel:(+256771323221)"
                  className="max-w-[calc(100%-40px)] break-words"
                >
                  +256771323221
                </Link>
              </div>
              <Link
                to="mailto:mailto:support@medrefhealthbridge.solutions"
                className="text-white text-base font-medium flex gap-3 items-center"
              >
                <span className="w-[30px] h-[30px] bg-white rounded-full flex items-center justify-center">
                  <MailIcon />
                </span>
                <span className="max-w-[calc(100%-40px)] break-words">
                  support@medrefhealthbridge.solutions
                </span>
              </Link>
              <Link
                to="/"
                className="text-white text-base font-medium flex gap-3 items-center"
              >
                <span className="w-[30px] h-[30px] bg-white rounded-full flex items-center justify-center">
                  <InternetIcon />
                </span>
                <span className="max-w-[calc(100%-40px)] break-words">
                  www.medrefhealthbridge.solutions
                </span>
              </Link>
              <Link
                to="/"
                className="text-white text-base font-medium flex gap-3 items-center"
              >
                <span className="w-[30px] h-[30px] bg-white rounded-full flex items-center justify-center">
                  <LocationIcon />
                </span>
                <span className="max-w-[calc(100%-40px)] break-words">
                  P.O. Box 129703, Kampala GPO
                </span>
              </Link>
            </div>
          </div>
          <div className="contact-form mx-auto lg:basis-[60%] w-full">
            <img
              src={ContactUsImg}
              alt="contact"
              className="h-full object-bottom object-contain"
            />
            {/* <form method="POST">
              <div className="flex flex-col">
                <Input
                  label="First name"
                  placeholder={"Enter your first Name"}
                  type="text"
                  name="firstName"
                  changeEvent={handleInputChange}
                  isErrorMsg={formError?.firstName}
                  attributes={{ value: formData?.firstName }}
                  isFocus={true}
                />

                <Input
                  label="Last name"
                  placeholder={"Enter your last Name"}
                  type="text"
                  name="lastName"
                  changeEvent={handleInputChange}
                  isErrorMsg={formError?.lastName}
                  attributes={{ value: formData?.lastName }}
                />
                <Input
                  label="Email"
                  placeholder={"Enter your email"}
                  type="email"
                  name="email"
                  changeEvent={handleInputChange}
                  isErrorMsg={formError?.email}
                  attributes={{ value: formData?.email }}
                />
                <Input
                  label="Contact No"
                  placeholder={"Enter your contact"}
                  type="tel"
                  name="contact"
                  changeEvent={handleInputChange}
                  isErrorMsg={formError?.contact}
                  attributes={{ maxvalue: 10, value: formData?.contact }}
                />
              </div>
              <div className="mt-6">
                <Button type="submit">Submit</Button>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
