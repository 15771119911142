import React from "react";
import profileImg from '../../../assets//images/profile.jpg';
import ES from '../../../assets/images/teams/Emmanuel.jpg';
import Josh from '../../../assets/images/teams/Josh.jpg';
import Sagar from '../../../assets/images/teams/Sagar.jpg';
import Halimah from '../../../assets/images/teams/Halimah.jpg';
import Himanshu from '../../../assets/images/teams/Himanshu.jpg';

const teamsData = {
  title: "Meet our team",
  description:
    "Discover the dedicated individuals behind Medref Health Bridge Solutions.",
  peoples: [
    {
      name: "Emmanuel Ssekyanzi",
      profileImg: ES,
      designation: "founder and ceo",
    },
    {
      name: "Joshua Asiimwe",
      profileImg: Josh,
      designation: "co-founder and coo",
    },
    {
      name: "Halimah Nabaggala",
      profileImg: Halimah,
      designation: "chief financial officer",
    },
    {
      name: "Himanshu Chhaparwal",
      profileImg: Himanshu,
      designation: "chief technology officer",
    },
    {
      name: "Sagar Prajapati",
      profileImg: Sagar,
      designation: "backend head",
    },
  ],
};
const OurTeam = () => {
  return (
    <div className="about-us-page">
      <div className="container">
        <div className="section-padding">
          <div className="lg:max-w-[70%] m-auto">
            <h2 className="text-color_444343 text-center title-underline mb-5">
              {teamsData?.title}
            </h2>
            <h4 className="text-color_444343 text-center pt-5">
              {teamsData?.description}
            </h4>
          </div>
          <div className="flex lg:gap-[50px] gap-[30px] flex-wrap lg:mt-20 mt-10 justify-center">
            {teamsData?.peoples.map((team, index) => {
              return (
                <div
                  key={index}
                  className="group flex flex-col lg:gap-8 gap-5 items-center text-center cursor-pointer"
                >
                  <div className="md:w-[200px] md:h-[200px] w-[150px] h-[150px] duration-300 group-hover:scale-105">
                    <img src={team.profileImg} alt='profile' className="w-full h-full object-contain rounded-full shadow-shadow25 object-top"/>
                  </div>
                  <div>
                    <h5 className="font-semibold">{team.name}</h5>
                    <h5 className="pt-1 capitalize">{team.designation}</h5>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
