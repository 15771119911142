import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "../../../scss/common/timeline.scss";

const events = [
  {
    title: "The Birth of Medref: A Hackathon's High-Octane Beginning",
    content: `Imagine this: not a stuffy boardroom filled with suits, but a high-octane hackathon in India, the air buzzing with caffeine and creative energy. That's where Medref Health Bridge Solutions, not some corporate giant, was born.`,
  },
  {
    title: `A Visionary Leader: Emmanuel Ssekyanzi's Mission`,
    content: `Leading the charge was Emmanuel Ssekyanzi, a visionary with a global team of students. They weren't there for the free pizza (although, let's be honest, that's always a bonus). They were there for a mission: healthcare justice. Their target? Saving the tiniest fighters – newborns and neonates – by revamping the clunky referral systems in India.`,
  },
  {
    title: `The Birth of SBRS: A Simple Solution with Big Impact`,
    content: `Their weapon? Forget fancy algorithms. They built a simple, yet effective system they called SBRS (Sick Baby Referral System). It was a game-changer, not just for the competition, but for the future of Medref.`,
  },
  {
    title: `Diverse Dream Team: Breaking Barriers and Winning Hearts`,
    content: `They didn't just win the national hackathon – they shattered a glass ceiling, becoming the first international student team to claim victory. Talk about a diverse dream team! These three African and three Indian students, along with their wise professor mentor, proved that collaboration across borders can spark revolutionary ideas.`,
  },
  {
    title: `From Trophy to Transformation: Incubation and Growth`,
    content: `But the story doesn't end with a trophy and bragging rights. SBRS got the VIP treatment – incubation by PIERC. Here, they learned the ropes of the real world and, more importantly, caught the entrepreneurial bug.`,
  },
  {
    title: `Expanding Horizons: Medref's Global Vision`,
    content: `However, their vision grew. They saw the bigger picture: healthcare as a fundamental right, not a privilege reserved for the lucky few. This realization led to a name change – Medref Health Bridge Solutions – A name that resonated with their evolving mission and unwavering vision.`,
  },
  {
    title: `Medref's Homecoming: A Commitment to Sub-Saharan Africa`,
    content: `Medref officially became a Ugandan company, and the team made a powerful decision: to take their solution home, to fight for the critical healthcare needs of Sub-Saharan Africa.`,
  },
  {
    title: `Medref's Legacy: Heroes of Healthcare Justice`,
    content: `This, my friends, is the origin story of Medref Health Bridge Solutions. Not a product of chance, but a testament to the power of innovation, collaboration, and a relentless pursuit of a world where healthcare isn't a luxury, but a basic human right. They're not just healthcare providers, they're healthcare heroes.`,
  },
];

const isBrowser = typeof window !== "undefined";

const getScrollPosition = ({ element, useWindow }) => {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
};

const useScrollPosition = (effect, deps, element, useWindow, wait) => {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    // add for reflow or reposition on resize or scroll
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, deps);
};

const EventItem = ({ content, title }) => {
  const timeItem = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const checkElement = (el) => {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    setIsVisible(checkElement(timeItem.current));
  }, []);

  useScrollPosition(({ currPos }) => {
    setIsVisible(checkElement(timeItem.current));
  });

  return (
    <li ref={timeItem} className={isVisible ? "inView" : null}>
      <div className="rounded-lg p-5">
        <h4 className="title text-color_444343">{title}</h4>
        <p
          className="description pt-3 text-color_444343"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </li>
  );
};

export const HistoryTimeline = () => {
  const scrollArea = useRef(null);

  const makeTimeline = (events) => {
    return events.map((item, index) => (
      <EventItem key={index} content={item.content} title={item.title} />
    ));
  };

  return (
    <div className="wrapper" ref={scrollArea}>
      <div className="timeline">
        <ul>{makeTimeline(events)}</ul>
      </div>
    </div>
  );
};
