import React, { useEffect, useRef, useState } from "react";
import { CloseIcon } from "../../assets/Icons";

const Popup = ({
  title,
  children,
  width = "840px",
  height = "501px",
  closeBtn = true,
  closeHandler,
}) => {
  // Screen height is less than popup height then
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [actualHeight, setActualHeight] = useState("");
  const popupBody = useRef(null);

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    // Set an initial timeout to update the popup height and add the resize event listener
    setTimeout(() => {
      window.addEventListener("resize", handleResize);
      setActualHeight(popupBody.current?.clientHeight + 40);
    }, 100);

    // Cleanup function to remove the resize event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [actualHeight]);

  // Another timeout to update the actual height after initial render
  setTimeout(() => {
    setActualHeight(popupBody.current?.clientHeight + 40);
  }, 200);

  return (
    <div className="fixed w-full h-full left-0 top-0 z-[103] popup-open scrollbarNone overflow-auto">
      <div
        className={`absolute left-1/2 bg-white -translate-x-1/2 w-full z-[12] max-lg:w-[85%] xl:rounded-30 rounded-20 2xl:p-[40px] lg:p-30 p-5 ${
          screenHeight < parseInt(actualHeight)
            ? "top-[10%]"
            : "top-1/2 -translate-y-1/2"
        } `}
        style={{ maxWidth: width }}
        ref={popupBody}
      >
        {closeBtn && (
          <div
            onClick={closeHandler}
            className="cursor-pointer 2xl:w-[50px] 2xl:h-[50px] h-30 w-30 rounded-full bg-white flex items-center justify-center shadow-cardShadow25 absolute 2xl:-top-5 2xl:-right-5 -top-3 -right-3 z-[13] "
          >
            <CloseIcon />
          </div>
        )}
        <div
          className={`bg-lightBg flex justify-between items-center mb-4 xl:pl-2.5`}
        >
          <h4 className="lg:text-xl md:text-lg text-base  leading-1_35 font-medium">
            {title}
          </h4>
        </div>
        <div className="2xl:pt-6 xl:p-2.5 relative">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
