import React from "react";
import image1 from "../../../assets/images/services/DiseaseSurveillance.png";
import image2 from "../../../assets/images/services/softwareTesting.png";
import image3 from "../../../assets/images/services/AIDataAnalytics2.png";
import image4 from "../../../assets/images/services/Telemedicine.png";
import image5 from "../../../assets/images/services/CapacityBuiding.png";


const serviceSection = {
  title: "Our services",
  description:
    "We provide to you the best choiches for you. Adjust it to your health needs and make sure your undergo treatment with our highly qualified doctors you can consult with us which type of service is suitable for your health",
  buttonText: "Learn more",
  buttonLink: "",
  services: [
    {
      title: "Mobile Health app developmet",
      description: "",
      img: image1,
    },
    {
      title: "Medical software testing",
      description: "",
      img: image2,
    },
    {
      title: "Medical software development",
      description: "",
      img: image3,
    },
    {
      title: "Telemedicine",
      description: "",
      img: image4,
    },
    {
      title: "Capacity Building Programs",
      description: "",
      img: image5,
    },
  ],
};

const OurServices = () => {
  return (
    <div className="container">
      <div className="flex gap-5 flex-wrap mx-auto">
        {serviceSection?.services?.length &&
          serviceSection?.services.map((service, index) => {
            return (
              <div
                className={`shadow-shadow25 bg-white lg:p-8 p-5 rounded-[20px] md:basis-[calc(33.3%-13.5px)] sm:basis-[calc(50%-10px)] basis-[calc(100%)] transition-all duration-300 hover:scale-105 cursor-default text-center`}
                key={index}
              >
                <img
                  src={service?.img}
                  alt="services"
                  className="lg:h-[200px] h-[150px] object-contain w-full object-center"
                  loading="lazy-loading"
                />
                {service?.title && (
                  <h5 className="mt-6 capitalize font-semibold">
                    {service?.title}
                  </h5>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default OurServices;
