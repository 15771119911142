import Layout from "./components/layouts/Layout";

function App() {
  return (
    <div className="App">
      <span className="popup-backdrop fixed w-full h-full left-0 top-0 bg-popupBg z-[100]" />
      <Layout />
    </div>
  );
}

export default App;
