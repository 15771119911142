import React from "react";
import BodyContent from "../components/layouts/BodyContent";
import HomeHeroSection from "../components/page/Home/HomeHeroSection";
import MissionVisionSection from "../components/page/Home/MissionVisionSection";
import "../scss/page/Home.scss";
import OurTeam from "../components/page/about-us/OurTeam";
import ImageGalley from "../components/common/ImageGalley";

const Home = () => {
  return (
    <BodyContent>
      <div>
        <HomeHeroSection />
        <MissionVisionSection />
        <div className="bg-white">
          <ImageGalley />
        </div>
        <OurTeam />
      </div>
    </BodyContent>
  );
};

export default Home;
