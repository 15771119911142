import React, { useEffect, useState } from "react";
import { MainLogo } from "../../assets/Icons";
import Menu from "../common/Menu";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const mobileMenuHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className={`header ${isMenuOpen && 'menu-open'} ${isScrolled ? 'is-scroll' :''}`}>
      <div className="header-container">
        <div className="flex gap-5 items-center justify-between">
          <Link to="/" className="cursor-pointer">
            <MainLogo />
          </Link>
          <div
            className="lg:hidden p-2.5 relative z-[100] cursor-pointer"
            onClick={mobileMenuHandler}
          >
            <span
              className={`headerHamburger ${isMenuOpen && "active"}`}
            ></span>
          </div>

          {/* Desktop Menu */}
          <div className="max-lg:hidden">
            <Menu />
          </div>

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="bg-white w-full h-[calc(100%-58px)] fixed top-[58px] left-0 z-[99]">
              <Menu setIsMenuOpen={setIsMenuOpen}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
