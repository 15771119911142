import React from "react";
import OurProducts from "../components/page/Home/OurProducts";
import OurServices from "../components/page/Home/OurServices";

const Services = () => {
  return (
    <div className="service-page">
      <div className="section-padding">
        <div className=" lg:mb-20 mb-10">
          <h2 className="text-color_444343 text-center title-underline">
            Our Services
          </h2>
        </div>
        <OurProducts />
      </div>
      <div className="section-padding bg-white">
        <div className="lg:mb-20 mb-10">
          <h2 className="text-color_444343 text-center title-underline">
            Other Services
          </h2>
        </div>
        <OurServices />
      </div>
    </div>
  );
};

export default Services;
