import React from "react";
import "../../../scss/page/Blog.scss";
import { Link } from "react-router-dom";
import NoImage from '../../../assets/images/no-image.svg';

const BlogPost = ({ data }) => {
  return (
    <div className="blog-post">
      <div className="blog flex flex-col relative z-[5] rounded-[14px] h-full">
        <div className="blog-image">
          <img
            src={data?.image || NoImage}
            alt="blogImage"
            className={`w-full h-full max-h-[200px] box-border rounded-t-[14px] object-top ${data?.image ? 'object-cover' : 'object-contain'}`}
          />
        </div>
        <div className="blog-content p-5 pb-7 flex-1 border-t border-gray">
          <h4>{data?.title}</h4>

          <Link to={data?.link} target="_blank" className="blog-button mt-5">
            Read More
          </Link>
        </div>
      </div>
      <div className="bg"></div>
      <div className="blob" />
    </div>
  );
};

export default BlogPost;
