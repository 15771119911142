import React from "react";
import Popup from "./Popup";

const ImagePopup = ({ image, closeHandler }) => {
  return (
    <Popup title="View Image" closeBtn={true} closeHandler={closeHandler}>
      <div>
        <img
          src={image}
          alt="view_image"
          className="object-contain w-full h-full max-w-[450px] max-h-[450px] mx-auto"
        />
      </div>
    </Popup>
  );
};

export default ImagePopup;
