import React from "react";
import { ReactTyped } from "react-typed";
import bannerImg from "../../../assets/images/doctors.png";
import medicalPlus from "../../../assets/images/medicalPlus.svg";
import Button from "../../common/Button";

const heroSection = {
  title: `<h1>Making Healthcare a <b>Universal Right</b>,</h1>`,
  description: "",
  buttonText: "",
  buttonLink: "#contact",
  bannerImg: bannerImg,
};

const HomeHeroSection = () => {
  return (
    <div className="relative section-padding">
      <div className="container relative z-[2]">
        <div className="flex lg:gap-5 gap-10 items-center max-lg:flex-col">
          <div className="absolute lg:top-0 lg:left-0 max-lg:right-0 max-lg:bottom-0">
            <img src={medicalPlus} alt="medical-plus" />
          </div>
          <div className="lg:basis-[calc(50%-10px)] w-full relative z-[2]">
            <div className="xl:w-[95%] lg:w-[90%] w-full text-color_444343">
              <div
                className="font-medium"
                dangerouslySetInnerHTML={{ __html: heroSection?.title }}
              ></div>
              <h1 className="font-bold highlighted-text">
                <ReactTyped
                  strings={["Not a Privilege"]}
                  typeSpeed={120}
                  loop
                  backSpeed={20}
                  cursorChar="|"
                  showCursor={true}
                />
              </h1>
              {heroSection?.description && (
                <p className="pt-3 text-color_7D7987">
                  {heroSection?.description}
                </p>
              )}
            </div>
            {heroSection?.buttonText && (
              <div className="lg:mt-8 mt-5 w-min">
                <Button btnType="link" clickHandler={heroSection?.buttonLink}>
                  {heroSection?.buttonText}
                </Button>
              </div>
            )}
          </div>
          <div className="lg:basis-[calc(50%-10px)] w-full border-b-2 border-[#263238]">
            <img
              src={heroSection?.bannerImg}
              alt="banner-img"
              className="max-lg:h-[250px] h-[400px] lg:object-right w-full object-contain"
            />
          </div>
        </div>
      </div>
      {/* <Link to="#services" id="scroll-down-animation" className="max-lg:hidden">
        <span className="mouse">
          <span className="move"></span>
        </span>
      </Link> */}
    </div>
  );
};

export default HomeHeroSection;
