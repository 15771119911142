import React from "react";
import Analytics from "../../../assets/images/Analytics.svg";
import Defaulter from "../../../assets/images/DefaulterIndentification.svg";
import Schedule from "../../../assets/images/scheduleReminder.svg";
import Antenatal from "../../../assets/images/antenatal.png";
import Record from "../../../assets/images/record.png";
import Vaccination from "../../../assets/images/vaccination.png";

const products = [
  {
    title: "UgaVax",
    description:
      "UgaVax is a specialized tool designed for child vaccination tracking. It automates the creation of vaccination schedules, sends reminders to parents or guardians, identifies vaccination defaulters, and generates analytical data for government use.<br/> <br/> UgaVax ensures that children receive their vaccinations on time, reducing the risk of vaccine-preventable diseases. The generated data is invaluable for government healthcare planning and decision-making.",

    features: [
      {
        title: "Scheduling & Reminders",
        description:
          "UgaVax automates the creation of vaccination schedules for children, sending timely reminders to parents or guardians.",
        img: Schedule,
        boxColor: "#A8D0E1",
      },
      {
        title: "Defaulter Identification",
        description:
          "It identifies vaccination defaulters, enabling healthcare providers to intervene and ensure all children receive the necessary vaccines.",
        img: Defaulter,
        boxColor: "#E8D4EC",
      },
      {
        title: "Data Analytics",
        description:
          "The system generates valuable analytical data, which is shared with the government for public health planning and decision-making.",
        img: Analytics,
        boxColor: "#B4E5D6",
      },
    ],
  },
  {
    title: "MamaCare",
    description:
      "Premature births pose a significant challenge in Sub-Saharan Africa, where the mortality rate for newborns is alarmingly high. Recognizing the urgent need for support and immediate care for parents of premature babies, Medref is proud to introduce the Mama Care Mobile App. This innovative solution aims to create a supportive community for parents, share success stories, and establish a network of nearby ICU facilities and ambulances for swift care and referrals.</br></br>The primary goal of Mama Care is to reduce the mortality rate of premature babies in Sub-Saharan Africa by providing timely support, information, and access to critical care resources. The app will foster a sense of community among parents facing similar challenges, creating a platform for sharing success stories and overcoming the emotional and practical hurdles associated with premature births. ",
    features: [
      {
        title: "Record Generation",
        description:
          "Mama Care simplifies the generation of digital antenatal cards for expectant mothers. Healthcare facilities can quickly input patient data, creating a secure digital record",
        img: Antenatal,
        boxColor: "#A8D0E1",
      },
      {
        title: "Antenatal Visit Tracking",
        description:
          "This feature enables healthcare professionals to monitor and track antenatal visits, ensuring mothers receive comprehensive care during pregnancy.",
        img: Record,
        boxColor: "#E8D4EC",
      },
      {
        title: "Vaccination Tracking",
        description:
          "Mama Care automates the tracking of child vaccinations, reducing the risk of missed doses and protecting children from vaccine-preventable diseases",
        img: Vaccination,
        boxColor: "#B4E5D6",
      },
    ],
  },
];
const OurProducts = () => {
  return (
    <div className="container">
      <div className="flex flex-col lg:gap-[70px] gap-[50px]">
        {products?.length &&
          products.map((data, index) => {
            return (
              <div
                className={`flex gap-10 items-start lg:odd:flex-row-reverse max-lg:flex-col-reverse`}
                key={index}
              >
                <div className="lg:basis-1/2 w-full">
                  <div className="flex gap-5 flex-wrap relative z-[2]">
                    {data?.features?.length &&
                      data?.features.map((feature, index) => {
                        return (
                          <div
                            className={`shadow-cardShadow  py-7 px-4 rounded-[20px] sm:basis-[calc(50%-10px)] basis-full transition-all duration-300 lg:hover:scale-105 cursor-default text-center`}
                            key={index}
                            style={{ backgroundColor: feature?.boxColor }}
                          >
                            {feature?.img && (
                              <img
                                src={feature?.img}
                                alt="feature"
                                className="md:h-[80px] h-[50px] object-contain w-full object-center mb-6"
                              />
                            )}
                            {feature?.title && (
                              <h4 className="capitalize font-semibold">
                                {feature?.title}
                              </h4>
                            )}
                            {feature?.description && (
                              <p className="mt-3 lg:text-[15px] text-sm text-color_7D7987">
                                {feature?.description}
                              </p>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="lg:basis-1/2 w-full relative">
                  <div className="relative z-[2]">
                    <h3 className="capitalize lg:text-2xl text-xl">
                      {data?.title}
                    </h3>
                    <p
                      className="lg:mt-7 mt-5 text-color_7D7987 md:text-base text-sm"
                      dangerouslySetInnerHTML={{ __html: data?.description }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default OurProducts;
