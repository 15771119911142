import React from "react";
import { HistoryTimeline } from "../components/page/about-us/HistoryTimeline";
import OurTeam from "../components/page/about-us/OurTeam";

const About = () => {
  return (
    <div className="about-us-page">
      <div className="container">
        <div className="section-padding">
          <h4 className="text-color_444343 text-center title-underline mb-5">
            About Medref
          </h4>
          <h2 className="text-color_444343 text-center pt-5">
            Leading the Way to Universal Healthcare
          </h2>

          <div className="w-full m-auto lg:max-w-[60%] md:max-w-[80%] max-w-full pt-7 text-center">
            <p>
              In a world where equitable healthcare remains an aspiration,
              MedRef Healthbridge Solutions emerges as a beacon of
              transformative innovation. Born from the crucible of the National
              Innovation Challenge in India, our company stands resolute in
              bridging the gaping healthcare divide.
            </p>
            <br />
            <p>
              Crucially, our roots extend to Sub-Saharan Africa through two of
              our founders, Emmanuel Ssekyanzi and Joshua Asiimwe. With a
              passionate zeal to bring our transformative technologies home,
              their vision propels us toward making a profound impact on global
              healthcare.
            </p>
          </div>
        </div>
      </div>
      <div className="our-history bg-white section-padding">
        <h2 className="text-color_444343 text-center mb-10 title-underline">
          History about Medref
        </h2>
        <HistoryTimeline />
      </div>
      <OurTeam />
    </div>
  );
};

export default About;
